import React from "react";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { ModalContentWrap } from "./index.styled";

const ModalContainer = ({ children, el = "div" }) => {
  const [container] = useState(() => {
    return document.createElement(el);
  });

  useEffect(() => {
    container.classList.add("portal");

    const parentElement = document.body;

    if (!parentElement) {
      return;
    }

    parentElement.appendChild(container);
    parentElement.style.overflow = "hidden";

    return () => {
      parentElement.style.overflow = "initial";
      parentElement.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
};

const Modal = ({ open, children, ...props }) => (
  <CSSTransition in={open} timeout={500} classNames={"fade"} unmountOnExit>
    <ModalContainer {...props}>
      <ModalContentWrap>{children}</ModalContentWrap>
    </ModalContainer>
  </CSSTransition>
);

export default Modal;
