import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

import MenuIcon from 'icons/menu.svg'
import MenuHoverIcon from 'icons/menu-hover.svg'

export const MenuButtonIcon = styled.button`
  -webkit-mask-image: url(${MenuIcon});
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;

  mask-image: url(${MenuIcon});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background: var(--black);
  border: none;

  height: ${calculateResponsiveSize(34)};
  width: ${calculateResponsiveSize(26)};
  cursor: pointer;
  transition: 1s;
  &:hover {
    -webkit-mask-image: url(${MenuHoverIcon});
    mask-image: url(${MenuHoverIcon});
  }
  &.white {
    background: white;
  }
  ${mediaMax('mobile')} {
    height: 22px;
    width: 34px;
  }
`
