import React from 'react'

import { useContext } from 'react'
import { LegalContext } from '../../../helpers/LegalProvider'
import { Secondary, Li, ItemEl } from './index.styled'

const SecondaryLinks = ({ onClose = () => {} }) => {
  const { setLegal, setHash } = useContext(LegalContext)

  const onLegalClick = () => {
    //setLegal(true)
    setHash('#terms')
    onClose()
  }

  const onPrivacyClick = () => {
    // setLegal(true)
    setHash('#privacy')
    onClose()
  }
  return (
    <Secondary>
      <Li onClick={onLegalClick}>
        <ItemEl className={'secondary'} to={'/legal'}>
          Legal
        </ItemEl>
      </Li>
      <Li onClick={onPrivacyClick}>
        <ItemEl className={'secondary'} to={'/privacy-policy'}>
          PRIVACY POLICY
        </ItemEl>
      </Li>
    </Secondary>
  )
}

export default SecondaryLinks
