import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PdfButton from "../ui/pdf-button";
import SecondaryLinks from "../ui/secondary-links";
import Button from "../ui/button";
import ApplyNow from "../ui/apply-now-button";
import ScheduleTour from "../ui/schedule-button";
import {
  Wrapper,
  Logo,
  Col,
  Center,
  Address,
  Links,
  LinkItem,
  WLogo,
  Icons,
  Icon,
  Row,
} from "./index.styled";

const Footer = () => {
  const {
    dataJson: {
      footerInfo: { address, phone, links, instalink },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          address
          phone
          instalink
          links {
            title
            link
          }
        }
      }
    }
  `);
  return (
    <>
      <Wrapper className={"hideMob"}>
        <Col>
          <PdfButton />
          <WLogo to="/" />
        </Col>
        <Center>
          <Logo />
          <Address>
            {address}
            <br></br>
            <a href={`tel:${phone}`}>{phone}</a>
          </Address>
        </Center>
        <Col className={"last"}>
          <SecondaryLinks />
          <Icons>
            <a href={instalink} target="_blank">
              <Icon className={"instagram"} />
            </a>
            <a
              href="https://www.hines.com/real-estate-services/product-types/living/americas-fair-housing-statement"
              target="_blank"
            >
              <Icon className={"housing"} />
            </a>
            <a href="https://www.hines.com/accessibility" target="_blank">
              <Icon className={"ada"} />
            </a>
            <Icon className={"no-smoking"} />
            <Icon className={"pet-friendly"} />
          </Icons>
        </Col>
      </Wrapper>
      <Wrapper className={"hideWeb"}>
        <Logo />
        <Row className={"center"}>
          <PdfButton />
          {/*  <ApplyNow className={['black'].join(' ')} />
          <ScheduleTour className={'black'} /> */}
        </Row>
        <Row>
          <Address>
            {address}
            <br></br>
            <a href={`tel:${phone}`}>{phone}</a>
          </Address>
          <SecondaryLinks />
        </Row>
        <Row>
          <WLogo />
          <Icons>
            <a href={instalink} target="_blank">
              <Icon className={"instagram"} />
            </a>
            <a
              href="https://www.hines.com/real-estate-services/product-types/living/americas-fair-housing-statement"
              target="_blank"
            >
              <Icon className={"housing"} />
            </a>
            <a href="https://www.hines.com/accessibility" target="_blank">
              <Icon className={"ada"} />
            </a>
            <Icon className={"no-smoking"} />
            <Icon className={"pet-friendly"} />
          </Icons>
        </Row>
      </Wrapper>
    </>
  );
};

export default Footer;
