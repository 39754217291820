import React, { useEffect } from "react";
import { useContext } from "react";
import MenuButton from "./menu-button";
import Button from "../ui/button";
import {
  Wrapper,
  Buttons,
  Logo,
  HeaderContent,
  HeaderIcon,
  CalenderIcon,
  CallIcon,
} from "./index.styled";
import { ThemeContext } from "../../helpers/ThemeProvider";
import NewLeasing from "../ui/new-leasing";
import ApplyNow from "../ui/apply-now-button";
import ScheduleTour from "../ui/schedule-button";

const Header = ({
  className,
  location,
  isFirstLoad,
  isFirstLeasing,
  setIsFirstLeasing,
}) => {
  const { siteTheme } = useContext(ThemeContext);

  return (
    <Wrapper className={[siteTheme, className].join(" ")} id={"header"}>
      <NewLeasing
        setIsFirstLeasing={setIsFirstLeasing}
        isFirstLoad={isFirstLoad}
        isFirstLeasing={isFirstLeasing}
      />

      <HeaderContent>
        <MenuButton className={siteTheme} />
        <HeaderIcon>
          <CalenderIcon
            className={siteTheme}
            href="https://my.hy.ly/tours/mapleterraceresidences/site?dd=0&popup=1&resize=---toursite&page_url=https%3A%2F%2Fmapleterraceresidences.com"
            target="_blank"
          />
          <CallIcon className={siteTheme} href="tel:(469) 721-1860" />
        </HeaderIcon>
        <Logo to="/" className={siteTheme} />
        <Buttons>
          <Button
            title="Floor Plans"
            className="beige-transparent"
            to="/floor-plans"
          />
          {/* <ScheduleTour
            className={[siteTheme, "beige-transparent"].join(" ")}
          /> */}
          {/* <ScheduleTour
            className={[siteTheme, 'beige-transparent'].join(' ')}
          />
          <ApplyNow className={[siteTheme, 'beige'].join(' ')} /> */}
        </Buttons>
      </HeaderContent>
    </Wrapper>
  );
};

export default Header;
