import React from 'react'
import CookieConsent from 'react-cookie-consent'
import useWindowSize from '../../../helpers/useWindowSize'

import { LinkItem } from './index.styled'

const CookiesBanner = () => {
  const { isMobile } = useWindowSize()

  return (
    <CookieConsent
      location={isMobile ? '20px' : 'bottom'}
      buttonText={isMobile ? 'OK' : 'Accept All'}
      disableStyles={true}
      containerClasses="cookies-banner"
      buttonClasses="cookies-banner-accept-btn"
      disableButtonStyles={true}
      enableDeclineButton={true}
      declineButtonClasses={'cookies-banner-decline-btn'}
      buttonWrapperClasses={'cookies-banner-btns'}
      contentClasses={'cookies-banner-content'}
      expires={150}
      //debug={true}
    >
      <div className={'cookies-banner-content'}>
        {isMobile
          ? 'This website uses '
          : 'We use cookies to improve your experience. By using this website you agree to our '}
        <LinkItem to={'/legal'}>
          {isMobile ? 'cookies' : 'Cookies Policy'}
        </LinkItem>
        .
        <LinkItem to={'/legal'} className={'button'}>
          Cookies Policy
        </LinkItem>
      </div>
    </CookieConsent>
  )
}
export default CookiesBanner
