import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";

import HeaderLogo from "icons/header-logo.svg";
import HeaderMobLogo from "icons/header-logo-mob.svg";

import Calender from "icons/tour-icon.svg";
import Call from "icons/phone-call-icon.svg";

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(138)};
  height: ${calculateResponsiveSize(72)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: cover;
  transition: 0.5s;
  background: var(--text);
  &.white {
    background: white;
  }

  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ${mediaMax("mobile")} {
    width: 159px;
    height: 74px;
    -webkit-mask: url(${HeaderMobLogo}) no-repeat;
    -webkit-mask-size: cover;
    position: static;
    transform: translateX(0);
  }
`;
export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(12)};
  ${mediaMax("mobile")} {
    display: none;
  }
`;

export const Wrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 100;
  background: var(--white);
  color: var(--text);
  &.transparent {
    background: transparent;
  }
  &.white {
    background: transparent;
  }
`;
export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${calculateResponsiveSize(30)};
  height: ${calculateResponsiveSize(120)};
  ${mediaMax("mobile")} {
    padding: 26px 25px;
    height: 123px;
    flex-direction: row-reverse;
    position: relative;
  }
`;
export const HeaderIcon = styled.div`
  display: none;

  ${mediaMax("mobile")} {
    display: flex;
    position: absolute;
    top: 26px;
    right: 78px;
  }
`;

export const CalenderIcon = styled.a`
  height: 22px;
  width: 17px;
  mask: url(${Calender}) no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  margin-right: 17px;
  background: black;
  &.white {
    background: white;
  }
`;

export const CallIcon = styled.a`
  height: 22px;
  width: 17px;
  mask: url(${Call}) no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background: black;
  &.white {
    background: white;
  }
`;
