import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'

import { mediaMax } from 'helpers/mediaQueries'

export const LayoutWrapper = styled.div`
  position: relative;
  height: 100%;
`

export const PageWrapper = styled.div`
  position: relative;
`

export const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  .page {
    top: 0;
    transition: opacity 0.25s;
    height: 100%;
    width: 100%;
  }
  .page.entering,
  .page.entered {
    position: relative;
    opacity: 1;
  }
  .page.exiting,
  .page.exited {
    position: absolute;
    opacity: 0;
  }
`
export const LinkItem = styled((props) => <Link {...props} />)`
  color: #fff;
  font-family: 'TT Norms Pro 300';
  font-size: ${calculateResponsiveSize(14)};
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-decoration-line: underline;
  background: none;
  border: none;
  cursor: pointer;
  &.button {
    border: 1px solid white;
    color: white;
    border-radius: ${calculateResponsiveSize(36)};
    padding: ${calculateResponsiveSize(6)} ${calculateResponsiveSize(12)};
    font-size: ${calculateResponsiveSize(12)};
    line-height: ${calculateResponsiveSize(12)};
    text-decoration-line: none;
    margin-left: ${calculateResponsiveSize(20)};
    &:hover {
      color: #000;
      background: white;
      transition: 0.5s;
    }
  }
  ${mediaMax('mobile')} {
    font-size: 14px;
    white-space: nowrap;
    &.button {
      border-radius: 36px;
      font-size: 12px;
      line-height: 12px;
      padding: 6px 12px;
      margin-left: 10px;
    }
  }
`
