import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

export const LogoWrap = styled.div`
  width: ${calculateResponsiveSize(137)};
  animation: 3s ease 0s normal forwards 1 fadein;
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 0;
    }
    66% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  ${mediaMax('mobile')} {
    width: 122px;
    animation: none;
  }
`
export const LogoTextWrap = styled.div`
  width: ${calculateResponsiveSize(200)};
  transition: 1s;
  animation: 3s ease 0s normal forwards 1 fadein1;
  @keyframes fadein1 {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${mediaMax('mobile')} {
    width: 200px;
    animation: none;
  }
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${calculateResponsiveSize(32)};
  ${mediaMax('mobile')} {
    gap: 32px;
  }
`
export const FullScreenImageSection = styled.section`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 200;
  background: var(--beige);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 1s;
  overflow: hidden;
  ${(props) =>
    props.isFinish === true &&
    css`
      transform: translateY(-100%);
      ${LogoTextWrap} {
        width: ${calculateResponsiveSize(138)};
        transform: translateY(40vh);
        animation: fadein2 1s;
        @keyframes fadein2 {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        ${mediaMax('mobile')} {
          width: 200px;
          animation: none;
        }
      }
    `}
`
