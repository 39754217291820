import React from 'react'
//import BrochurePdf from 'static/brochure.pdf'
import Button from '../button'

const PdfButton = ({
  title = 'Brochure',
  to = '/brochure.pdf' /* BrochurePdf */,
  ...props
}) => {
  return (
    <Button
      href={to}
      {...props}
      target="_blank"
      as={'a'}
      title={title}
      className={'black'}
    />
  )
}

export default PdfButton
