import styled from "styled-components";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";
import CloseIcon from "icons/close.svg";
import WhiteDollarIcon from "images/dollar_white.svg";

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: ${calculateResponsiveSize(80)};
  margin-top: -${calculateResponsiveSize(80)};
  z-index: 100;
  transition: margin-top 1s;
  overflow: hidden;
  background: #292929e5;
  justify-content: center;

  &.show {
    margin-top: 0;
  }
  ${mediaMax("mobile")} {
    gap: 16px;
    height: auto;
    padding: 2px 25px;
    align-items: center;
    height: 130px;
    margin-top: -130px;
    flex-direction: column;
  }
`;

export const SpecialContent = styled.div`
  width: calc(100% - ${calculateResponsiveSize(40)});

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px ${calculateResponsiveSize(25)};

  gap: ${calculateResponsiveSize(60)};

  color: white;
`;

export const Title = styled.h2`
  font-family: "Adobe Caslon Pro Italic";
  font-size: ${calculateResponsiveSize(36)};
  font-style: italic;
  font-weight: 400;
  // display: flex;
  align-items: baseline;
  line-height: ${calculateResponsiveSize(36)};
  letter-spacing: 0em;

  span {
    font-size: 18px;
  }

  ${mediaMax("mobile")} {
    font-size: 18px;
    line-height: 24px;

    span {
      font-size: 16px;
    }
  }
`;
export const Text = styled.p`
  color: white;
  font-family: "TT Norms Pro 300";
  font-size: ${calculateResponsiveSize(14)};
  font-style: normal;
  line-height: 100%;
  max-width: ${calculateResponsiveSize(431)};
  ${mediaMax("mobile")} {
    font-size: 14px;
    line-height: 19.6px;
    max-width: none;
    width: 100%;
    text-align: center;
  }
`;
export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(20)};
  ${mediaMax("mobile")} {
    gap: 16px;
  }
`;

export const CloseButton = styled.button`
  -webkit-mask-image: url(${CloseIcon});
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;

  mask-image: url(${CloseIcon});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background: white;
  border: none;

  height: ${calculateResponsiveSize(18)};
  width: ${calculateResponsiveSize(18)};
  cursor: pointer;

  position: absolute;
  right: ${calculateResponsiveSize(30)};
  ${mediaMax("mobile")} {
    height: 18px;
    width: 18px;
    top: 15px;
    right: 15px;
  }
`;
export const WhiteDollar = styled.div`
  background-image: url(${WhiteDollarIcon});
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
`;
