import React, { useState, useEffect } from "react";

import { StaticImage } from "gatsby-plugin-image";

import {
  FullScreenImageSection,
  LogoWrap,
  LogoTextWrap,
  Content,
} from "./index.styled";

const MainIntro = ({ setIsFirstLoad = () => {} }) => {
  const [isFinish, seIsFinish] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      seIsFinish(true);
    }, 3000);
    setTimeout(() => {
      document.body.style.overflow = "initial";
      setIsFirstLoad(false);
    }, 4000);
  }, []);
  return (
    <FullScreenImageSection isFinish={isFinish}>
      <Content>
        <LogoWrap>
          <StaticImage alt="" src={"../../../images/logo-main.svg"} />
        </LogoWrap>
        <LogoTextWrap>
          <StaticImage alt="" src={"../../../images/logo-main-text.svg"} />
        </LogoTextWrap>
      </Content>
    </FullScreenImageSection>
  );
};
export default MainIntro;
