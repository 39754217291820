import React, { createContext, useCallback, useState } from "react";

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [siteTheme, setSiteTheme] = useState("");

  const setTheme = useCallback((name) => {
    setSiteTheme(name);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        siteTheme,
        setTheme,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
