import React, { useState } from 'react'
import { MenuButtonIcon } from './index.styled'

const MenuButton = ({ className }) => {
  const onClick = () => {
    const menu = document.querySelector('#menu')
    menu.classList.toggle('open')
  }

  return <MenuButtonIcon onClick={onClick} className={className} />
}

export default MenuButton
