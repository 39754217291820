import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { mediaMax } from 'helpers/mediaQueries'

export const LayoutWrapper = styled.div`
  position: relative;
  height: 100%;
`

export const PageWrapper = styled.div`
  position: relative;
`

export const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  .page {
    top: 0;
    transition: opacity 0.25s;
    height: 100%;
    width: 100%;
  }
  .page.entering,
  .page.entered {
    position: relative;
    opacity: 1;
  }
  .page.exiting,
  .page.exited {
    position: absolute;
    opacity: 0;
  }
`
export const LinkItem = styled((props) => <Link {...props} />)`
  color: #fff;
  font-family: 'TT Norms Pro 300';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-decoration-line: underline;
`
