import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

export const Li = styled.li``

export const ItemEl = styled((props) => <Link {...props} />)`
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
  font-family: 'TT Norms Pro 500';
  font-size: ${calculateResponsiveSize(10)};
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  line-height: 100%;
  letter-spacing: ${calculateResponsiveSize(1)};
  text-transform: uppercase;
  ${mediaMax('mobile')} {
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.1em;
  }
`

export const Secondary = styled.ul`
  display: flex;
  align-items: flex-end;
  gap: ${calculateResponsiveSize(23)};
  ${mediaMax('mobile')} {
    gap: 20px;
  }
`
