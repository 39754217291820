import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import CloseIcon from 'icons/close.svg'
import W_Logo from 'icons/wlogo.svg'

export const MenuWrap = styled.div`
  height: 100%;
  position: fixed;
  background: var(--beige);
  color: #fff;
  top: 0;
  left: 0;
  z-index: 20;
  padding: ${calculateResponsiveSize(46)} ${calculateResponsiveSize(30)};
  transform: translateX(-100%);
  transition: 1s;
  width: ${calculateResponsiveSize(420)};

  display: flex;
  flex-direction: column;
  ${mediaMax('mobile')} {
    width: 100%;
    opacity: 0;
    transition: opacity 1s ease 0s, transform 0s ease 1s;
    padding: 26px 25px;
  }
`
export const ItemStyles = css`
  color: var(--text);
  text-align: center;
  font-family: 'TT Norms Pro 300';
  font-size: ${calculateResponsiveSize(36)};
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  text-decoration: none;
  cursor: pointer;
  display: block;
  opacity: 0;
  transform: translateX(-50px);

  transition: ${({ index }) => `1s ease calc(${index + 1} * 0.1s)`};

  ${mediaMax('mobile')} {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 0em;
  }
`
export const WLogo = styled((props) => <div {...props} />)`
  width: ${calculateResponsiveSize(81)};
  height: ${calculateResponsiveSize(24)};
  background: url(${W_Logo}) no-repeat;
  background-size: contain;
  display: flex;
  opacity: 0;
  transform: translateX(-50px);
  transition: 1s ease calc(8 * 0.1s);

  ${mediaMax('mobile')} {
    width: 81px;
    height: 24px;
  }
`
export const Btns = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(18)};

  opacity: 0;
  transform: translateX(-50px);
  transition: 1s ease calc(8 * 0.1s);
  ${mediaMax('mobile')} {
    gap: 13px;
  }
`

export const Item = styled((props) => <Link {...props} />)`
  ${ItemStyles}
`

export const Li = styled.li``
export const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 19;

  background: #000;
  opacity: 0.3;
  display: none;
`

export const Primary = styled.ul`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  gap: ${calculateResponsiveSize(30)};
  border-bottom: 1px solid var(--beige);
  margin-bottom: ${calculateResponsiveSize(30)};
  padding-bottom: ${calculateResponsiveSize(30)};
  transition: 1s ease calc(6 * 0.1s);

  ${mediaMax('mobile')} {
    gap: 30px;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
`

export const CloseButton = styled.button`
  -webkit-mask-image: url(${CloseIcon});
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;

  mask-image: url(${CloseIcon});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background: var(--black);
  border: none;

  height: ${calculateResponsiveSize(26)};
  width: ${calculateResponsiveSize(26)};
  cursor: pointer;
  ${mediaMax('mobile')} {
    height: 19px;
    width: 19px;
  }
`
export const PortalLink = styled((props) => <a {...props} />)`
  color: var(--text);
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: 'TT Norms Pro 400';
  font-size: ${calculateResponsiveSize(12)};
  font-style: normal;
  font-weight: 400;
  line-height: ${calculateResponsiveSize(12)};
  text-decoration: none;
  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 20px;
  }
`
export const Wrapper = styled.div`
  z-index: 101;
  position: relative;
  &.open {
    ${MenuWrap} {
      transform: translateX(0);
    }
    ${Overlay} {
      display: block;
    }
    ${Item} {
      opacity: 1;
      transform: translateX(0);
    }
    ${Btns} {
      opacity: 1;
      transform: translateX(0);
    }
    ${Primary} {
      border-bottom: 1px solid #000;
    }
  }

  ${mediaMax('mobile')} {
    &.open {
      ${MenuWrap} {
        transition: opacity 1s ease 0s, transform 0s ease 0s;
        transform: translateX(0);
        opacity: 1;
      }
      ${Overlay} {
        display: none;
      }
    }
  }
`
export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &.top {
    justify-content: space-between;
  }

  ${mediaMax('mobile')} {
    &.top {
      flex-direction: row-reverse;
    }
    &.col {
      flex-direction: column;
      gap: 30px;
      .row {
        gap: 13px;
      }
    }
  }
`

export const Center = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`
