import React, { createContext, useCallback, useState } from "react";

export const LegalContext = createContext();

const LegalProvider = ({ children }) => {
  const [openLegal, setOpenLegal] = useState(false);
  const [hash, setHash] = useState("");

  const setLegal = useCallback((name) => {
    setOpenLegal(name);
  }, []);

  return (
    <LegalContext.Provider
      value={{
        openLegal,
        setLegal,
        hash,
        setHash,
      }}>
      {children}
    </LegalContext.Provider>
  );
};

export default LegalProvider;
