import React, { useContext } from 'react'
import { gsap, ScrollTrigger } from 'helpers/gsap'
import { ThemeContext } from '../../../helpers/ThemeProvider'

const HeaderTransition = ({ status, children }) => {
  const { setTheme } = useContext(ThemeContext)

  const refWrapper = React.useRef(null)
  const gsapContextRef = React.useRef(null)

  React.useLayoutEffect(() => {
    if (!gsapContextRef.current && status === 'entered') {
      gsapContextRef.current = gsap.context(() => {
        const whiteSections = gsap.utils.toArray('.section-white')
        whiteSections.forEach((section, index) => {
          ScrollTrigger.create({
            trigger: section,
            start: `top top${index === 0 ? '+=100px' : ''}`,
            onEnter: () => {
              setTheme('white')
            },
            onLeave: () => {
              setTheme('')
            },
            onEnterBack: () => {
              setTheme('white')
            },
            onLeaveBack: () => {
              setTheme('')
            },
          })
        })
        const transparentSections = gsap.utils.toArray('.section-transparent')
        transparentSections.forEach((section, index) => {
          ScrollTrigger.create({
            trigger: section,
            start: `top top${index === 0 ? '+=100px' : ''}`,
            onEnter: () => {
              setTheme('transparent')
            },
            onLeave: () => {
              setTheme('')
            },
            onEnterBack: () => {
              setTheme('transparent')
            },
            onLeaveBack: () => {
              setTheme('')
            },
          })
        })
      }, refWrapper)
    } else if (
      gsapContextRef.current &&
      (status === 'exiting' || status === 'exited')
    ) {
      gsapContextRef.current.revert()
    }
    return () =>
      gsapContextRef.current ? gsapContextRef.current.revert() : null
  }, [status])

  return <div ref={refWrapper}>{children}</div>
}
export default HeaderTransition
