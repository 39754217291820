import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import CloseIcon from '../../icons/close.svg'
import HeaderLogo from '../../icons/header-logo.svg'
import HeaderMobLogo from 'icons/header-logo-mob.svg'

export const Logo = styled.div`
  width: ${calculateResponsiveSize(138)};
  height: ${calculateResponsiveSize(72)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: cover;
  transition: 0.5s;
  background: var(--text);

  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ${mediaMax('mobile')} {
    width: 159px;
    height: 74px;
    -webkit-mask: url(${HeaderMobLogo}) no-repeat;
    -webkit-mask-size: cover;
    position: static;
    transform: translateX(0);
  }
`

export const ModalWrapper = styled.div`
  padding: 0px ${calculateResponsiveSize(30)} ${calculateResponsiveSize(50)};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - calc(100vh - 100%));
  background-color: #fff;
  z-index: 200;
  overflow: auto;
  scroll-behavior: smooth;
  ${mediaMax('mobile')} {
    padding: 0 25px 25px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  z-index: 11;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0 ${calculateResponsiveSize(30)};
  height: ${calculateResponsiveSize(120)};
  background: white;
  ${mediaMax('mobile')} {
    padding: 26px 25px;
    height: 123px;
    justify-content: space-between;
    align-items: flex-start;
  }
`
export const LegalContent = styled.div`
  display: flex;
  padding-bottom: ${calculateResponsiveSize(120)};
  position: relative;
  top: ${calculateResponsiveSize(120)};
  aside {
    position: fixed;
    display: flex;
    flex-direction: column;
    padding-right: ${calculateResponsiveSize(14)};
    padding-top: ${calculateResponsiveSize(40)};
    padding-left: ${calculateResponsiveSize(20)};
  }
  section {
    color: #292929;
    width: 80%;
    margin-inline: auto;

    a,
    p {
      padding-top: ${calculateResponsiveSize(40)};
      color: #292929;

      font-family: 'TT Norms Pro 300';
      font-size: 14px;
      line-height: 140%;
    }
  }

  ${mediaMax('mobile')} {
    aside {
      background-color: rgb(255, 255, 255);
      padding-top: 109px;
      flex-direction: row;
      width: 100%;
    }
    section {
      width: 100%;
      margin-left: 0px;
      a,
      p {
        padding-top: 150px;
      }
      p#terms {
        margin-bottom: -155px;
      }
    }
  }
`
export const CloseButton = styled.button`
  background-color: transparent;
  color: var(--text);
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: 'TT Norms Pro 400';
  font-size: ${calculateResponsiveSize(12)};
  font-style: normal;
  font-weight: 400;
  line-height: ${calculateResponsiveSize(12)};
  padding: ${calculateResponsiveSize(6)} ${calculateResponsiveSize(30)};
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: 0.5s;
  border: 1px solid var(--beige);
  border-radius: ${calculateResponsiveSize(36)};
  &:hover {
    background-color: var(--beige);
  }
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 12px;
    padding: 6px 14px;
    border-radius: 36px;
  }
`
export const Item = styled.div`
  font-family: 'TT Norms Pro 500', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${calculateResponsiveSize(18)};
  line-height: ${calculateResponsiveSize(21)};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
  opacity: 0.25;
  transition: 0.25s ease-out;
  padding-bottom: ${calculateResponsiveSize(40)};
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 18px;
    width: 50%;
  }
`
