import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import CloseIcon from '../../icons/close.svg'
import ArrowNextIcon from '../../icons/rightArrow.svg'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${calculateResponsiveSize(30)} ${calculateResponsiveSize(89)};
  ${mediaMax('mobile')} {
    padding: 24px;
  }
`
export const SwiperWrap = styled.div`
  height: calc(100%);
  display: flex;
  align-items: center;
  ${mediaMax('mobile')} {
    height: calc(100%);
  }
`

export const Next = styled.div`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;

  background-image: url(${ArrowNextIcon});
  cursor: pointer;
  width: ${calculateResponsiveSize(30)};
  height: ${calculateResponsiveSize(20)};

  position: absolute;
  right: ${calculateResponsiveSize(30)};
  top: calc(50% - ${calculateResponsiveSize(20 / 2)});
  /*  &.swiper-button-disabled {
    opacity: 0;
  } */
  ${mediaMax('mobile')} {
    bottom: 24px;
    top: auto;
    right: 20px;
    width: 30px;
    height: 20px;
  }
`
export const Prev = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${ArrowNextIcon});
  z-index: 10;
  width: ${calculateResponsiveSize(30)};
  height: ${calculateResponsiveSize(20)};
  cursor: pointer;
  transform: rotate(-180deg);
  /*  &.swiper-button-disabled {
    opacity: 0;
  } */

  position: absolute;
  left: ${calculateResponsiveSize(30)};
  top: calc(50% - ${calculateResponsiveSize(20 / 2)});
  ${mediaMax('mobile')} {
    bottom: 24px;
    top: auto;
    left: 20px;
    width: 30px;
    height: 20px;
  }
`

export const CloseButton = styled.button`
  -webkit-mask-position: right center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-image: url(${CloseIcon});
  -webkit-mask-size: contain;
  width: ${calculateResponsiveSize(26)};
  height: ${calculateResponsiveSize(26)};

  position: absolute;
  right: ${calculateResponsiveSize(30)};
  top: ${calculateResponsiveSize(30)};
  cursor: pointer;
  background: white;
  appearance: none;
  border: none;
  z-index: 10;
  ${mediaMax('mobile')} {
    width: 26px;
    height: 26px;
    right: 30px;
    top: 30px;
  }
`
export const Caption = styled.div`
  color: white;
  text-align: center;
  font-family: 'TT Norms Pro 300';
  font-size: ${calculateResponsiveSize(16)};
  font-style: italic;
  line-height: 120%;
  letter-spacing: 0em;

  position: absolute;
  bottom: -40px;
  ${mediaMax('mobile')} {
    font-size: 16px;
  }
`
export const ImageWrap = styled.div`
  overflow: hidden;
`
