import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'

export const Element = styled(({ El, ...props }) => {
  const Element = El ?? Link
  return <Element {...props} />
})`
  background-color: transparent;
  color: var(--text);
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: 'TT Norms Pro 400';
  font-size: ${calculateResponsiveSize(12)};
  font-style: normal;
  font-weight: 400;
  line-height: ${calculateResponsiveSize(12)};
  padding: ${calculateResponsiveSize(6)} ${calculateResponsiveSize(12)};
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: 0.5s;
  border: 1px solid var(--beige);
  min-width: ${calculateResponsiveSize(120)};
  justify-content: center;
  border-radius: ${calculateResponsiveSize(36)};

  &:visited {
    color: var(--text);
  }
  &:hover {
    background-color: var(--beige);
  }
  &.beige-transparent {
    background-color: var(--beige);
    &.white {
      background-color: white;
      color: #000;
      border: 1px solid white;
      &:hover {
        background-color: #000;
        border: 1px solid #000;
        color: white;
      }
    }
    &.transparent {
      background-color: transparent;
      color: #000;
      border: 1px solid #000;
    }
    &:hover {
      background-color: #000;
      color: var(--white);
    }
  }
  &.beige {
    background-color: var(--beige);
    &.white {
      background-color: white;
      color: #000;
      border: 1px solid white;
      &:hover {
        background-color: #000;
        border: 1px solid #000;
        color: white;
      }
    }
    &.transparent {
      background-color: #000;
      color: white;
      border: 1px solid #000;
      &:hover {
        background-color: white;
        border: 1px solid white;
        color: #000;
      }
    }
    &:hover {
      background-color: #000;
      color: var(--white);
    }
  }
  &.white {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    &:hover {
      background-color: white;
      color: #000;
    }
  }
  &.transparent {
    background-color: transparent;
    color: #000;
    border: 1px solid #000;
    &:hover {
      background-color: #000;
      color: white;
    }
  }
  &.black {
    background-color: #000;
    color: white;
    border: 1px solid #000;
    &:hover {
      background-color: white;
      border: 1px solid white;
      color: #000;
    }
  }
  &.webHide {
    display: none;
  }
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 12px;
    padding: 6px 10px;
    min-width: 106px;
    white-space: nowrap;
    border-radius: 36px;
    &.webHide {
      display: inline-flex;
    }
    &.hideMob {
      display: none;
    }
  }
`
