import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import FooterLogo from 'icons/footer-logo.svg'
import W_Logo from 'icons/wlogo.svg'

import PetFriendlyIcon from 'icons/petfriendly.svg'
import ADAIcon from 'icons/ada.svg'
import NoSmokingIcon from 'icons/no-smoking.svg'
import HousingIcon from 'icons/housing.svg'
import InstaIcon from 'icons/instagram.svg'

export const Logo = styled((props) => <Link {...props} to="/" />)`
  width: ${calculateResponsiveSize(45)};
  height: ${calculateResponsiveSize(72)};
  background: url(${FooterLogo}) no-repeat;
  background-size: contain;
  transition: 0.5s;
  display: flex;
  ${mediaMax('mobile')} {
    width: 43px;
    height: 69px;
  }
`
export const WLogo = styled((props) => <div {...props} />)`
  width: ${calculateResponsiveSize(81)};
  height: ${calculateResponsiveSize(24)};
  background: url(${W_Logo}) no-repeat;
  background-size: contain;
  display: flex;
  ${mediaMax('mobile')} {
    width: 81px;
    height: 24px;
  }
`
export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(80)};
`
export const Address = styled.div`
  white-space: break-spaces;
  color: rgba(41, 41, 41, 0.75);
  font-family: 'TT Norms Pro 400';
  font-size: ${calculateResponsiveSize(14)};
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: ${calculateResponsiveSize(24)};
  a{
    color: rgba(41, 41, 41, 0.75);
    text-decoration: none;
  }
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(24)};
  margin-left: auto;
`
export const LinkItem = styled((props) => <Link {...props} />)`
  color: rgba(41, 41, 41, 0.75);
  font-feature-settings: 'liga' off;
  font-family: 'TT Norms Pro 500';
  font-size: ${calculateResponsiveSize(10)};
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: ${calculateResponsiveSize(1)};
  text-transform: uppercase;
  text-decoration: none;
`

export const Icon = styled.span`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  width: ${calculateResponsiveSize(24)};
  height: ${calculateResponsiveSize(24)};
  display: block;
  &.housing {
    background-image: url(${HousingIcon});
    width: ${calculateResponsiveSize(26)};
  }
  &.ada {
    background-image: url(${ADAIcon});
    width: ${calculateResponsiveSize(22)};
  }
  &.pet-friendly {
    background-image: url(${PetFriendlyIcon});
    width: ${calculateResponsiveSize(25)};
  }
  &.no-smoking {
    background-image: url(${NoSmokingIcon});
    width: ${calculateResponsiveSize(25)};
  }
  &.instagram {
    background-image: url(${InstaIcon});
    width: ${calculateResponsiveSize(30)};
  }
  ${mediaMax('mobile')} {
    height: 16px;
    &.housing {
      width: 16px;
    }
    &.ada {
      width: 14px;
    }
    &.pet-friendly {
      width: 16px;
    }
    &.no-smoking {
      width: 16px;
    }
  }
`
export const Icons = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(12)};
  justify-content: flex-end;
  ${mediaMax('mobile')} {
    gap: 8px;
  }
`

export const Wrapper = styled.footer`
  height: ${calculateResponsiveSize(150)};
  border-top: 1px solid var(--black);
  background: var(--beige);
  display: flex;
  align-items: center;
  padding: ${calculateResponsiveSize(20)} ${calculateResponsiveSize(60)};
  color: var(--text);
  justify-content: space-between;
  &.hideWeb {
    display: none;
  }

  ${mediaMax('mobile')} {
    padding: 18px 25px 72px;
    height: 273px;
    flex-direction: column;

    &.hideWeb {
      display: flex;
    }
    &.hideMob {
      display: none;
    }
  }
`
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 20%;
  align-items: flex-start;
  &.last {
    align-items: flex-end;
  }
`
export const Center = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  &.center {
    justify-content: center;
  }
`
