import React, { useEffect, useLayoutEffect } from 'react'
import { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Modal from '../ui/modal'
import Overlay from '../ui/overlay'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'

import { Navigation, EffectFade } from 'swiper'
import {
  Container,
  Content,
  SwiperWrap,
  CloseButton,
  Prev,
  Next,
  Caption,
  ImageWrap,
} from './index.styled'

const Gallery = ({ open = true, onClose = () => {}, imageId }) => {
  const { homeContent, amenitiesContent, galleryContent } = useStaticQuery(graphql`
    query GalleryQuery {
      homeContent {
        content {
          content {
            images {
              file {
                id
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
              caption
            }
          }
        }
      }
      amenitiesContent {
        content {
          content {
            images {
              file {
                id
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
              caption
            }
          }
        }
      }
    }
  `)

  let nodes = []
  const res = [...homeContent?.content, ...amenitiesContent?.content]

  res?.forEach((item) => {
    item.content.forEach((c) => {
        const filterImages = c.images.filter((i) => i?.file?.extension === 'jpg' && i.caption)
        nodes = [...nodes, ...filterImages]
    })
  })

  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const index = nodes.findIndex(({ file: { id } }) => id === imageId)

  const options = {
    loop: true, //TODO: native nav stop working with loop
    uniqueNavElements: true,
    initialSlide: index === -1 ? 0 : index,
    modules: [Navigation, EffectFade],
    effect: 'fade',
    fadeEffect: { crossFade: true },
    className: 'gallery-swiper',
  }
  const [activeSlide, setActiveSlide] = useState(index === -1 ? 0 : index)

  useEffect(() => {
    if (thumbsSwiper) {
      thumbsSwiper.update()
    }
  }, [open, thumbsSwiper])

  return (
    <Modal open={open}>
      <Container>
        <CloseButton
          type="button"
          onClick={() => {
            onClose()
            setThumbsSwiper(null)
          }}
        ></CloseButton>

        <Content>
          <SwiperWrap>
            <Swiper
              onSwiper={setThumbsSwiper}
              {...options}
              nested={true}
              onSlideChange={(s) => {
                setActiveSlide(s.realIndex)
              }}
            >
              {nodes.map((item, index) => {
                return (
                  <SwiperSlide key={`gallery-item-${index}`}>
                    <ImageWrap>
                      <GatsbyImage
                        image={getImage(
                          item?.file?.childImageSharp?.gatsbyImageData,
                        )}
                        alt={item?.alt || ''}
                        style={{ height: '100%' }}
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </ImageWrap>
                    <Caption>{item?.caption || ''}</Caption>
                  </SwiperSlide>
                )
              })}
            </Swiper>
            <Prev
              onClick={() => {
                if (activeSlide === 0) {
                  thumbsSwiper.slideTo(nodes.length - 1)
                  setActiveSlide(nodes.length - 1)
                } else {
                  thumbsSwiper.slideTo(activeSlide - 1)
                  setActiveSlide(activeSlide - 1)
                }
              }}
            ></Prev>
            <Next
              onClick={() => {
                if (activeSlide === nodes.length - 1) {
                  thumbsSwiper.slideTo(0)
                  setActiveSlide(0)
                } else {
                  thumbsSwiper.slideTo(activeSlide + 1)
                  setActiveSlide(activeSlide + 1)
                }
              }}
            ></Next>
          </SwiperWrap>
        </Content>
        <Overlay />
      </Container>
    </Modal>
  )
}

export default Gallery
