import React, { useContext, useEffect, useRef } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'

import {
  ModalWrapper,
  Header,
  LegalContent,
  CloseButton,
  Logo,
  Item,
} from './index.styled'
import { LegalContext } from '../../helpers/LegalProvider'

const LegalModal = () => {
  const { setLegal, openLegal, setHash, hash } = useContext(LegalContext)
  const location = useLocation()
  const refWrap = useRef(null)

  useEffect(() => {
    if (hash) {
      const el = document.querySelector(hash)
      if (refWrap.current) refWrap.current.scrollTo(0, el.offsetTop)
    }
    return () => setHash('')
  }, [hash])

  // if (!openLegal) return null

  return (
    <ModalWrapper className={'fade legal-wrapper'} ref={refWrap}>
      <Header>
        <Logo />
        <CloseButton
          type={'button'}
          onClick={() => {
            setLegal(false)
            // navigate(location.pathname)
            navigate(-1)
          }}
        >
          Back
        </CloseButton>
      </Header>

      <LegalContent>
        {/* <aside>
          <Item onClick={() => setHash('#terms')}>TERMS OF USE</Item>
          <Item onClick={() => setHash('#privacy')}>PRIVACY POLICY</Item>
        </aside> */}
        <section className={'legal-content'}>
          <p id={'terms'} className={'copy_t2'}>
            Terms of Use
            <br />
            <br />
            Effective: February 25, 2021
            <br />
            <br />
            3003 MAPLE LP (“Hines”), which is the developer of MAPLE TERRACE, is
            pleased to offer you access to the web site accessible at{' '}
            <a href="https://mapleterracedallas.com/" target={'_blank'}>
              MAPLETERRACEDALLAS.COM
            </a>{' '}
            (the “Site”). The Site is governed by these Terms of Use. Please
            read these Terms of Use carefully. By accessing the Site or
            downloading any materials from the Site, you agree to these Terms of
            Use. If you do not agree to them, do not use the Site or download
            any materials, and exit the Site immediately. These Terms of Use are
            in addition to any other agreement between you and Hines (or any of
            its affiliates), including those relating to any of the information
            or materials available via the Site.
            <br />
            <br />
            Authorized Use. Use of the Site is for informational purposes only.
            Hines is not responsible or liable for the accuracy, completeness,
            usefulness or availability of any information or other content,
            data, text, URLs, graphics or any other materials (collectively, the
            “Content”) transmitted or made available via the Site. Hines is not
            responsible or liable for any decisions made in reliance on such
            information.
            <br />
            <br />
            Any use or attempted use of the Site (I) for any unlawful,
            unauthorized, fraudulent or malicious purpose, or (ii) that could
            damage, disable, overburden, or impair any server, or the network(s)
            connected to any server, or (iii) that could interfere with any
            other party’s use and enjoyment of the Site, or (iv) to gain
            unauthorized access to any other accounts, computer systems or
            networks connected to any server or systems through hacking,
            password mining or any other means, or (v) to access systems, data
            or information not intended by Hines to be made accessible to a
            user, or (vi) to obtain or attempt to obtain any materials or
            information through any means not intentionally made available by
            Hines, or (vii) for any use other than the purpose for which it was
            intended, is prohibited.
            <br />
            <br />
            Site Content. All content accessible within the Site (including
            without limitation, the “look and feel” of the Site, all text,
            formatting, graphics, designs, animation, images, audio, and other
            content, as well as all trademarks and service marks) is proprietary
            to us or to other parties who have consented to our use of it in
            accordance with applicable law, including applicable international
            treaties.
            <br />
            <br />
            You may not (I) copy, reproduce, transmit, alter, publish,
            distribute, or create derivative works from the Site content; (ii)
            frame or deep-link to the Site; or (iii) use meta tags or any other
            hidden text utilizing our or our content providers’ trademarks or
            service marks on or in connection with another domain name or Site.
            The only exception to this is that you may print out a copy of pages
            solely for your personal use. In so doing, you agree that you will
            not remove or alter any copyright, trademark or any other
            proprietary notice or legend appearing in any of the Site content.
            <br />
            <br />
            All names, logos and trademarks (“Hines’ Trademarks”) are the
            property of Hines, its affiliates, related companies or its
            licensors or joint venture partners. Hines’ Trademarks may be used
            only as stated in these Terms of Use or with prior written
            permission from Hines.
            <br />
            <br />
            Submissions. No questions, comments, suggestions, information,
            ideas, concepts, photographs, graphics or other materials, whether
            oral, written or electronic (collectively, “submissions”), will be
            considered or treated as confidential information. Accordingly, do
            not submit or send any submission to us that you consider contains
            confidential or proprietary information. Any submission, or any
            other content of any sort transmitted by you, other than personal
            data which is covered by our Privacy Policy, will be considered
            non-confidential and non-proprietary and Hines will not be subject
            to any restrictions or obligations with regard to it.
            <br />
            <br />
            Privacy.Privacy. Unless otherwise addressed in these Terms of Use,
            your use of the Site is subject to Hines’ Privacy Policy. It is
            important that you read and understand the terms of the Privacy
            Policy. Hines may cooperate with and disclose information to any
            authority, government official or third-party, without giving any
            notice to you, in connection with any investigation, proceeding or
            claim arising from illegal action or infringement, whether related
            or unrelated to your use or misuse of the Site.
            <br />
            <br />
            Violations.Violations. Hines reserves the right to take whatever
            lawful actions it may deem appropriate in response to actual or
            suspected violations of the foregoing, including, without
            limitation, the suspension or termination of your access to the Site
            and/or account with the Site if Hines offers you an account. Hines
            may cooperate with legal authorities and/or third parties in the
            investigation of any suspected or alleged crime or civil wrong.
            Except as may be expressly limited by Hines’ Privacy Policy, Hines
            reserves the right at all times to disclose any information as Hines
            deems necessary to satisfy any applicable law, regulation, legal
            process or governmental request, or to edit, refuse to post or to
            remove any information or materials you provide to Hines through the
            Site, in whole or in part, in Hines’ sole discretion.
            <br />
            <br />
            Third Party Web Sites. In some instances, the Site may reference or
            link to third party web sites. Should you choose to visit those web
            sites, please remember that Hines is not responsible for their
            content, their terms of use, or their privacy policies. We encourage
            you to read and review the terms of use and other legal terms and
            policies of all web sites you visit. You understand that Hines will
            not be liable to you in respect of any loss or damage that you may
            suffer by using those web sites. You agree that you will not involve
            Hines in any dispute between you and a third party.
            <br />
            <br />
            Indemnity. You agree to indemnify and hold Hines, and its owners,
            partners and affiliates, and their respective officers, directors,
            employees, representatives, contractors and agents (collectively,
            the “Indemnified Parties”) harmless from any actions, liability,
            loss, claim, damage or expense, including attorneys’ fees and
            expenses, related to your (I) use of the Site, (ii) violation of
            these Terms of Use, or (iii) reliance on any of the materials or
            information or other content available via the Site.
            <br />
            <br />
            Liability and Warranty Disclaimer. Hines makes no representation or
            warranties, express or implied, with respect to the accuracy or
            completeness of the content, information, graphics, text, links, or
            other material contained on this Site and is not responsible for any
            errors or omissions in the content of this Site. To the extent
            permitted by applicable law, everything on the Site is provided “as
            is” without warranty of any kind, either express or implied,
            including, but not limited to, the implied warranties of
            merchantability, fitness for a particular purpose, habitability, or
            non-infringement. You acknowledge that any reliance upon the Site or
            the information, material, systems, services or products contained
            or discussed therein shall be at your sole risk. To the extent
            permitted by applicable law, in no event will any Indemnified Party
            be liable for any damages whatsoever, including special, indirect,
            consequential or incidental damages or damages for loss of profits,
            revenue, use, or data, whether brought in contract or tort, arising
            out of or connected with the Site or your use or reliance upon any
            of the content or any information accessed from the Site. In no
            event will the collective liability of Hines and its subsidiaries,
            affiliates, licensors, service providers, content providers,
            employees, agents, officers, and directors to any party (regardless
            of the form of action, whether in contract, tort, or otherwise)
            exceed one thousand dollars ($1,000). Additional disclaimers may
            appear from time to time within the body of the Site and are
            incorporated herein by reference.
            <br />
            <br />
            No Investment Advice. None of the information provided through the
            Site constitutes investment advice, and the views expressed should
            not be taken as advice to buy or sell any security. Decisions based
            on information contained or provided through the Site are your sole
            responsibility and are made at your own risk.
            <br />
            <br />
            Nothing on or in the Site shall be considered a solicitation or
            offer to buy or sell any security, future, option or other financial
            instrument or to offer or provide any investment, tax, financial or
            legal advice or service to any person in any jurisdiction. Any
            overviews provided through the Site are intended to be general in
            nature. While intended to be helpful, these overviews are no
            substitute for professional tax, financial or legal advice.
            Investors should seek such professional, financial or legal advice
            for their particular situation.
            <br />
            <br />
            No Offer. This content posted on the Site is provided solely for
            informational purposes. This Site is not intended to be, and does
            not constitute, an offer for the sale, purchase or lease of real
            property. Except for your agreement to abide by these Terms of Use,
            nothing in this Site or your use of the content contained herein
            shall be interpreted as giving rise to or forming the basis of a
            contract, commitment or obligation.
            <br />
            <br />
            Real Property Disclaimers / Disclosures. All information posted on
            this Site is subject to change without notice. The information and
            materials contained in this Site regarding real estate or property
            developments, including photographs, renderings, plans, prices,
            facilities, land uses, improvements, amenities, dimensions,
            specifications, views, scenes, materials and availability, are
            proposed, are conceptual only and are subject to change,
            modification or cancellation without notice or obligation. Scenes,
            pictures, drawings, illustrations and/or views shown may be artist
            renderings and may be locations or activities not on, or related to,
            the property or development. Actual views may vary, and views
            described or depicted cannot be relied upon as the actual view from
            any proposed unit, home, lot, amenity or other improvement or area.
            Maps are not to scale and are for relative location purposes only.
            There is no guarantee that the facilities, services, features,
            amenities, improvements, views, scenes or specifications described,
            shown or depicted within the Site will be constructed or otherwise
            provided, and if constructed or provided, that they will be of the
            same type, style, size or nature as described or depicted. Ownership
            or rental of a residence in a development community does not
            guarantee access to, or the right to use, amenities, such as clubs
            or marinas, which may require the purchase of separate memberships
            and may be subject to other conditions on use. Certain amenities may
            not be complete and completion is neither warranted nor guaranteed.
            <br />
            <br />
            Disputes, Forum, and Governing Law. These Terms of Use and all
            matters relating to the Site, including any disputes, claims, suits,
            or causes of action concerning the interpretation, violation,
            invalidity, non-performance, or termination of these Terms of Use,
            shall be governed by and construed in accordance with the laws of
            the State of Texas without giving effect to any choice or conflict
            of law provision or rules. If you take legal action relating to
            these Terms of Use or the Site, you agree to file such action only
            in state court in Harris County, Texas, or federal court within the
            Southern District of Texas, and you consent and submit to the
            personal jurisdiction of those courts for the purposes of litigating
            any such action or defending any action brought against you relating
            to these Terms of Use or the Site. You hereby waive any and all
            objections to the exercise of jurisdiction over you by such courts
            and to venue in such courts.
            <br />
            <br />
            Definitions. When we say “we” or “us” or “Hines” in these Terms of
            Use, we mean 3003 MAPLE LP, our affiliates and those agents we use
            to provide services on our behalf. When we say “Site”, we mean{' '}
            <a href="https://mapleterracedallas.com/" target={'_blank'}>
              MAPLETERRACEDALLAS.COM
            </a>
            <br />
            <br />
            Other. Hines reserves, in its sole discretion, the right to revise
            these Terms of Use at any time by updating this posting and to
            monitor and remove postings and/or discontinue Site availability at
            any time without notice.
            <br />
            <br />
            If any term, condition or provision of these Terms of Use is
            determined to be unlawful, invalid, void or for any reason
            unenforceable, the validity and enforceability of the remaining
            terms, conditions and provisions shall not in any way be affected or
            impaired thereby.
            <br />
            <br />
            If you have any questions, complaints or comments regarding this
            Site, you may contact us at: <br />
            MAPLE TERRACE <br />
            Webmaster <br />
            3003 MAPLE LP <br />
            2800 Post Oak Blvd <br />
            Houston, TX 77056 <br />
            Email:{' '}
            <a href="mailto:HinesCorporate.Communications@hines.com">
              HinesCorporate.Communications@hines.com
            </a>{' '}
            <br />
            <br />
            <br />
            <br />
            <br />
          </p>
    
        </section>
      </LegalContent>
    </ModalWrapper>
  )
}

export default LegalModal
